import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import SEO from "../components/seo";
import BrowseContent from '../components/browseContent';
import { connect } from "react-redux"
import PageWrapper from "../components/pageWrapper";
import 'intl/locale-data/jsonp/sv';
import i18nMessages from '../i18n/sv.json';
import { FormattedMessage, injectIntl } from 'react-intl';


class Liked extends React.Component {

 	render() {
 		if (this.props.initialLoader) {
 			return (
 				<div className="chatPageWrapper page">
					<div className='dcLoader medium preLoader' />
 				</div>
 			);
 		} else {
        	return (
				<PageWrapper location={this.props.location} lang="sv" i18nMessages={i18nMessages}>
					<BrowseContent langUrl="/sv" sort={3} />
				</PageWrapper>
			);
 		}
       
		
	}
}

function mapStateToProps(state) {
	const { loggedIn, totalUnread, initialLoader } = state
	return { loggedIn: loggedIn,
			 totalUnread: totalUnread,
			 initialLoader: initialLoader 
	}
}


export default injectIntl(connect(
	mapStateToProps
)(Liked));